import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from '@/router'
import App from '@/App.vue'
import Plausible from 'plausible-tracker'
import { isPreviewMode } from '@/utils/helpers'

const app = createApp(App)
export const pinia = createPinia()

export const plausible = Plausible({
  domain: import.meta.env.VITE_DOMAIN,
  apiHost: import.meta.env.VITE_PLAUSIBLE_API_HOST,
  trackLocalhost: false
})

// not an iframe
if (!isPreviewMode()) {
  plausible.enableAutoPageviews()
}

app.use(pinia)
app.use(router)

app.mount('#app')
