 
import { showDevMessage } from '@/utils/helpers'
import { register } from 'register-service-worker'
import api from '@/api'

export default function registerServiceWorker(fetchCore) {
  if (!navigator.serviceWorker) {
    console.error('Service worker is not supported.')
    fetchCore()
    return
  }

  register('/static/service-worker.js', {
    registrationOptions: { scope: '/', type: 'module' },
    ready() {
      setTimeout(() => {
        api.getGuide() // to cache api
        showDevMessage('success', 'API loaded & cached.')
      }, 2000)
      showDevMessage('success', 'Service worker is ready.')
    },
    registered() {
      fetchCore()
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content has downloaded.')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      fetchCore()

      if (!window.location.href.includes('localhost')) {
        console.error('Error during service worker registration!')
        console.error(error)
      }
    }
  })
}
